.aboutPagePhoto {
  object-fit: cover; 
  width: 100%;
  height: 700px;
  margin-top: 10px;
}

.aboutPage {
    padding: 60px 0;
  }

.abSection {
    background-color: #f9f9f9;
    padding: 60px 0;
    width: 100%;
}
  
.aboutHeader {
    text-align: center;
    margin-bottom: 40px;    
  }
  
.aboutHeader h1 {
    font-size: 48px;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

.abContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

.abContent h2 {
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: -15px;
}

.abContent p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
}
  
  .missionSection {
    max-width: 800px;
    margin: 40px auto 0;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .missionSection h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .missionSection p {
    font-size: 18px;
    color: #666;
  }
  

  /* Team Section */
.teamSection {
    background-color: #fff;
    padding: 60px 20px;
    width: 100%;
    text-align: center;
}

.teamSection h2 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #333;
}

.teamGrid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
}

.teamMember {
    max-width: 300px;
    text-align: center;
}

.teamPhoto {
  width: 100%;
  max-width: 500px;
  height: 350px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}


.teamMember h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.teamMember p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.direction {
  font-style: italic;
  color: #888;
  margin-top: -10px;
  font-size: 0.9em;
}


/* Ensure the element is initially hidden */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
}

/* Define the fade-in animation */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation when the element is visible */
.fade-in.visible {
  animation: fadeIn 1s ease-in forwards;
}